.dynamic-heading {
    display: inline-block;
    cursor:help;
    color: var(--platinum);
  }
  
  .dynamic-char {
    display: inline-block;
    transition: transform 0.1s ease-in-out;
  }
  
  .dynamic-char:hover {
    transform: scale(1.25);
  }

.custom-modal .modal-header,
.custom-modal .modal-footer {
  border: none;
}

.custom-modal .modal-content {
    background: rgba(255,255,255,0.07);
    backdrop-filter:blur(15px);
    overflow:hidden;
    color: #ffffff;
    max-height: max-content;
    border: solid #ffffff4e;
}

.TextModalCSS {
  background-color: rgba(21, 22, 20, 0.647);
  border-radius: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
