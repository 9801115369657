 #customButton {
    border: var(--platinum) solid ;
    color: var(--platinum) ;
    border-radius: 5%;
    border-width: 1.2px;
}

#customButton:hover {
    background-color: var(--platinum);
    color: var(--fresh-black);
}


.myNavbar{
    border-bottom: var(--platinum) solid;
    background-color: var(--fresh-black);
}

#contactButton {
    border: var(--platinum) solid ;
    color: var(--platinum) ;
    border-radius: 5%;
    border-width: 1.2px;
}

#contactButton:hover {
    background-color: var(--platinum);
    color: var(--fresh-black);
}


#projectsButton {
    border: var(--platinum) solid ;
    color: var(--platinum) ;
    border-radius: 5%;
    border-width: 1.2px;
}

#projectsButton:hover {
    background-color: var(--platinum);
    color: var(--fresh-black);
}


