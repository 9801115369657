body {
    font-family: "basic-sans-black", sans-serif;
    font-weight: 300;
    font-style: normal;
    background: rgb(188, 253, 255);
    padding-top: 71.4px;
}

.sans-font {
    font-family: "basic-sans-black", sans-serif;
    font-weight: 300;
    font-style: normal;
    text-decoration: solid var(--fresh-black);
}

.poleno-font-bold {
    font-family: poleno, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poleno-font-semibold {
    font-family: poleno, sans-serif;
    font-weight: 400;
    font-style: normal;
}


html {
    overflow: hidden;
    height: 100%;
}

body {
    overflow: auto;
    height: 100%;
}