.blend-img {
    opacity: 0.90; /* Adjust the opacity value as needed */
    filter: grayscale(20%) contrast(120%);
}

.wrapper {
max-width: 100%;
overflow: hidden;
}

.tech {
white-space: nowrap;
overflow: hidden;
display: inline-block;
animation: tech 10s linear infinite; /* corrected animation name */
}

.tech span {
display: inline-block;
}

@keyframes tech {
0% {
    transform: translate3d(0, 0, 0);
    }
    100% {
    transform: translate3d(-50%, 0, 0);
    }
}

.card-effect {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    background-color: var(--platinum) !important;
}

.Card-Link {
    color: var(--fresh-black);
    background-color: transparent;
    /* text-decoration: none; */
}

.different {
    background-color: var(--old-rose) !important;
}

.custom-text {
    color: var(--fresh-black);
    
}

.custom-text2 {
    color: var(--old-rose);
}


.custom-text3 {
    color: var(--platinum);
}


.card-effect:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#icons:hover path{
    fill: var(--old-rose);
    transition: 0.25s;
}

#footer {
    bottom: 0;
}

#wavy-line {
    stroke: var(--old-rose);
}

#text-effect:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
  

.project-imgs {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor:crosshair ;
}

.project-imgs:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}


/* ------------------------------------------------------------------------------------------- */
/* CSS for responsiveness */

/* Global styles for mobile */
@media (max-width: 576px) {

    .homepage-container {
        width: 100%;
        max-width: 540px;

}
   .homepage-container .different , 
   .homepage-container .tech-card {
        width: 350px;
   }

   .custom-text {
        font-size: 0.875rem;
   }
    
}
